import React, { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, OrbitControls } from '@react-three/drei'

function BikeModel() {
  const { scene } = useGLTF('/models/bike3.glb')
  const modelRef = useRef()

  useFrame((state, delta) => {
    if (modelRef.current) {
      // Rotate around the X-axis instead of Y-axis
      modelRef.current.rotation.x += delta * 0.5
    }
  })

  return (
    <primitive 
      object={scene} 
      ref={modelRef} 
      position={[0, 0, 0]} 
      scale={[2, 2, 2]} // Increase the scale to make the model larger
    />
  )
}

export default function Bike3test() {
  return (
    <Canvas 
      style={{ width: '100%', height: '100%' }}
      camera={{ position: [0, 0, 5], fov: 50 }} // Adjust camera position and field of view
    >
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <BikeModel />
      <OrbitControls enableRotate={true} enablePan={false} enableZoom={true} />
    </Canvas>
  )
}

useGLTF.preload('/models/bike3.glb')
