import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UploadProducts.css';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

// Fallback choices in case the API call fails
const FALLBACK_WEIGHT_UNIT_CHOICES = [
  ['g', 'Grams'],
  ['kg', 'Kilograms'],
];

const UploadProducts = ({ onBack }) => {
  const [supplier, setSupplier] = useState({ name: '', email: '', website: '' });
  const [componentTypes, setComponentTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [subtypes, setSubtypes] = useState([]);
  const [selectedSubtype, setSelectedSubtype] = useState('');
  const [commonFields, setCommonFields] = useState({});
  const [productFields, setProductFields] = useState({});
  const [productData, setProductData] = useState({});
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [supplierInfoSaved, setSupplierInfoSaved] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [materialChoices, setMaterialChoices] = useState([]);
  const [weightUnitChoices, setWeightUnitChoices] = useState(FALLBACK_WEIGHT_UNIT_CHOICES);

  useEffect(() => {
    // Fetch component types
    axios.get(`${API_BASE_URL}/component-types/`)
      .then(response => {
        setComponentTypes(response.data);
      })
      .catch(err => {
        console.error("Error fetching component types:", err);
        setError("Failed to load component types. Please try again later.");
      });

    // Fetch material choices
    axios.get(`${API_BASE_URL}/material-choices/`)
      .then(response => {
        setMaterialChoices(Object.entries(response.data));
      })
      .catch(err => {
        console.error("Error fetching material choices:", err);
        setError("Failed to load material choices. Using default options.");
      });

    // Fetch weight unit choices
    axios.get(`${API_BASE_URL}/weight-unit-choices/`)
      .then(response => {
        setWeightUnitChoices(Object.entries(response.data));
      })
      .catch(err => {
        console.error("Error fetching weight unit choices:", err);
        setError("Failed to load weight unit choices. Using default options.");
        // Fallback to hardcoded choices if the fetch fails
        setWeightUnitChoices(FALLBACK_WEIGHT_UNIT_CHOICES);
      });
  }, []);

  useEffect(() => {
    if (selectedType) {
      const componentType = componentTypes.find(type => type.componenttypeid === parseInt(selectedType));
      if (componentType && componentType.has_subtypes) {
        axios.get(`${API_BASE_URL}/component-subtypes/${selectedType}/`)
          .then(response => {
            setSubtypes(response.data);
          })
          .catch(err => {
            console.error("Error fetching subtypes:", err);
            setError("Failed to load subtypes. Please try again later.");
          });
      } else {
        setSubtypes([]);
        setSelectedSubtype('');
      }

      axios.get(`${API_BASE_URL}/component-form/${selectedType}/`)
        .then(response => {
          setCommonFields(response.data.common_fields || {});
          setProductFields(response.data.specific_fields || {});
          setProductData({});
        })
        .catch(err => {
          console.error("Error fetching component form:", err);
          setError("Failed to load component form. Please try again later.");
        });
    }
  }, [selectedType, componentTypes]);

  const handleSupplierChange = (e) => {
    setSupplier({ ...supplier, [e.target.name]: e.target.value });
  };

  const handleProductChange = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleSupplierInfoSave = async (e) => {
    e.preventDefault();
    try {
      // Validate website URL
      let websiteUrl = supplier.website.trim();

      // Add 'http://' prefix if missing
      if (websiteUrl && !websiteUrl.startsWith('http://') && !websiteUrl.startsWith('https://')) {
        websiteUrl = `http://${websiteUrl}`;
      }

      // Use a more robust URL validation pattern
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$','i' // fragment locator
      );

      if (websiteUrl && !urlPattern.test(websiteUrl)) {
        setError('Please enter a valid website URL');
        return;
      }

      // Ensure supplier name is not empty
      if (!supplier.name.trim()) {
        setError('Company Name is required');
        return;
      }

      const supplierData = {
        suppliername: supplier.name,
        contactemail: supplier.email,
        website: websiteUrl || null,
      };

      console.log('Submitting supplier data:', supplierData);

      const response = await axios.post(`${API_BASE_URL}/suppliers/`, supplierData);
      setSupplierId(response.data.supplierid);
      setSupplier({...supplier, id: response.data.supplierid});
      setSupplierInfoSaved(true);
      setStep(2);
      setError(null);
      alert('Supplier information saved successfully! Now let\'s upload the product details.');
    } catch (error) {
      console.error('Error saving supplier information:', error);
      setError('Error saving supplier information. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedType) {
        setError('Please select a component type');
        return;
      }

      const componentData = {
        ...productData,
        componenttypeid: parseInt(selectedType),
        componentsubtypeid: selectedSubtype ? parseInt(selectedSubtype) : null,
        supplier: {
          supplierid: supplierId,
          // Include other supplier fields if needed
          suppliername: supplier.name,
          contactemail: supplier.email,
          website: supplier.website,
        }
      };

      // Validate required fields
      const allFields = { ...commonFields, ...productFields };
      for (const [fieldName, fieldInfo] of Object.entries(allFields)) {
        if (fieldInfo.required && !componentData[fieldName] && fieldName !== 'supplierid' && fieldName !== 'componentid') {
          setError(`${fieldInfo.label || fieldName} is required`);
          return;
        }
      }

      const submitUrl = selectedSubtype 
        ? `${API_BASE_URL}/submit-component/${selectedType}/${selectedSubtype}/`
        : `${API_BASE_URL}/submit-component/${selectedType}/`;
      
      const response = await axios.post(submitUrl, componentData);

      alert(`Product submitted successfully! Component ID: ${response.data.componentid}`);
      setProductData({});
      setSelectedType('');
      setSelectedSubtype('');
    } catch (error) {
      console.error('Error submitting product:', error);
      if (error.response && error.response.data) {
        let errorMessage = 'Error submitting product:\n';
        if (typeof error.response.data === 'object') {
          errorMessage += Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
            .join('\n');
        } else {
          errorMessage += JSON.stringify(error.response.data);
        }
        setError(errorMessage);
      } else {
        setError('Error submitting product. Please try again.');
      }
    }
  };

  const renderSupplierInfo = () => (
    <div className="step-container">
      <h3>Step 1: Provide Company Information</h3>
      <form onSubmit={handleSupplierInfoSave}>
        <div className="form-group">
          <label htmlFor="supplierName">Company Name *</label>
          <input
            id="supplierName"
            name="name"
            value={supplier.name}
            onChange={handleSupplierChange}
            required
            disabled={supplierInfoSaved}
          />
        </div>
        <div className="form-group">
          <label htmlFor="supplierEmail">Email *</label>
          <input
            id="supplierEmail"
            name="email"
            value={supplier.email}
            onChange={handleSupplierChange}
            required
            type="email"
            disabled={supplierInfoSaved}
          />
        </div>
        <div className="form-group">
          <label htmlFor="supplierWebsite">Website (optional)</label>
          <input
            id="supplierWebsite"
            name="website"
            value={supplier.website}
            onChange={handleSupplierChange}
            disabled={supplierInfoSaved}
            placeholder="https://example.com"
          />
        </div>
        {!supplierInfoSaved && (
          <button type="submit" className="save-btn">Save and Continue</button>
        )}
      </form>
    </div>
  );

  const renderProductInfo = () => (
    <div className="step-container">
      <h3>Step 2: Upload Products</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="componentType">Component Type *</label>
          <select
            id="componentType"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            required
          >
            <option value="">Select Component Type</option>
            {componentTypes.map(type => (
              <option key={type.componenttypeid} value={type.componenttypeid}>
                {type.componenttypename}
              </option>
            ))}
          </select>
        </div>

        {componentTypes.find(type => type.componenttypeid === parseInt(selectedType))?.has_subtypes && (
          <div className="form-group">
            <label htmlFor="componentSubtype">Component Subtype</label>
            <select
              id="componentSubtype"
              value={selectedSubtype}
              onChange={(e) => setSelectedSubtype(e.target.value)}
              required
            >
              <option value="">Select Subtype</option>
              {subtypes.map(subtype => (
                <option key={subtype.componentsubtypeid} value={subtype.componentsubtypeid}>
                  {subtype.subtypename}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedType && (
          <div className="dynamic-fields">
            <h4>Product Details</h4>
            {Object.entries({ ...commonFields, ...productFields }).map(([fieldName, fieldInfo]) => (
              <React.Fragment key={fieldName}>
                {renderField(fieldName, fieldInfo)}
              </React.Fragment>
            ))}
          </div>
        )}

        <button type="submit" className="submit-btn">Submit Product</button>
      </form>
    </div>
  );

  const renderField = (fieldName, fieldInfo) => {
    if (!fieldInfo) return null;
    if (fieldName === 'supplierid' || fieldName === 'componentid') return null;

    switch(fieldInfo.type) {
      case 'ChoiceField':
      case 'ForeignKey':
        if (fieldName === 'material') {
          return (
            <div className="form-group">
              <label htmlFor={fieldName}>{fieldInfo.label || fieldName}</label>
              <select
                id={fieldName}
                name={fieldName}
                value={productData[fieldName] || ''}
                onChange={handleProductChange}
                required={fieldInfo.required}
              >
                <option value="">Select Material</option>
                {materialChoices.map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          );
        }
        return (
          <div className="form-group">
            <label htmlFor={fieldName}>{fieldInfo.label || fieldName}</label>
            <select
              id={fieldName}
              name={fieldName}
              value={productData[fieldName] || ''}
              onChange={handleProductChange}
              required={fieldInfo.required}
            >
              <option value="">Select {fieldInfo.label}</option>
              {(fieldInfo.choices || []).map(choice => (
                <option key={choice.value} value={choice.value}>
                  {choice.display_name}
                </option>
              ))}
            </select>
          </div>
        );
      case 'ManyToMany':
        return (
          <div className="form-group">
            <label htmlFor={fieldName}>{fieldInfo.label || fieldName}</label>
            <select
              multiple
              id={fieldName}
              name={fieldName}
              value={productData[fieldName] || []}
              onChange={handleProductChange}
              required={fieldInfo.required}
            >
              {(fieldInfo.choices || []).map(choice => (
                <option key={choice.value} value={choice.value}>
                  {choice.display_name}
                </option>
              ))}
            </select>
          </div>
        );
      case 'BooleanField':
        return (
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              id={fieldName}
              name={fieldName}
              checked={productData[fieldName] || false}
              onChange={(e) => handleProductChange({
                target: { name: fieldName, value: e.target.checked }
              })}
            />
            <label htmlFor={fieldName}>{fieldInfo.label || fieldName}</label>
          </div>
        );
      case 'DecimalField':
      case 'IntegerField':
        const unitFieldName = `${fieldName}_unit`;
        return (
          <div className="form-group">
            <label htmlFor={fieldName}>{fieldInfo.label || fieldName}</label>
            <div className="input-group">
              <input 
                type="number"
                id={fieldName}
                name={fieldName}
                value={productData[fieldName] || ''}
                onChange={handleProductChange}
                required={fieldInfo.required}
                step={fieldInfo.type === 'DecimalField' ? '0.01' : '1'}
              />
              {fieldName === 'weight' && (
                <select
                  name={unitFieldName}
                  value={productData[unitFieldName] || ''}
                  onChange={handleProductChange}
                  required={true}
                >
                  <option value="">Select Unit</option>
                  {weightUnitChoices.map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className="form-group">
            <label htmlFor={fieldName}>{fieldInfo.label || fieldName}</label>
            <input 
              type="text"
              id={fieldName}
              name={fieldName}
              value={productData[fieldName] || ''}
              onChange={handleProductChange}
              required={fieldInfo.required}
            />
          </div>
        );
    }
  };

  return (
    <div className="upload-products">
      <h2>Upload Products</h2>
      {error && <div className="error-message">{error}</div>}
      {step === 1 && renderSupplierInfo()}
      {step === 2 && renderProductInfo()}
      <div className="form-actions">
        <button type="button" onClick={onBack} className="back-btn">Back to Dashboard</button>
      </div>
    </div>
  );
};

export default UploadProducts;