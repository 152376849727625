import React from 'react';

// The Header component receives 'title' as a prop
function Header({ title }) {
  return (
    <header className="App-header">
      <h1>{title}</h1>
    </header>
  );
}

export default Header;