import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import DesignBike from './DesignBike';
import UploadProducts from './UploadProducts';
import Bike3test from './Bike3test';
import ErrorBoundary from './ErrorBoundary';

function App() {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleBack = () => {
    setSelectedOption(null);
  };

  return (
    <div className="App">
      <Header title="Imagine Bike" />

      <div className={`content ${selectedOption === 'upload' ? 'upload-mode' : ''}`}>
        <div className="bike-container">
          <ErrorBoundary>
            <Bike3test />
          </ErrorBoundary>
        </div>

        <div className="right-panel">
          {!selectedOption && (
            <>
              <button onClick={() => handleOptionSelect('design')} className="option-btn">Design your 3D bike</button>
              <button onClick={() => handleOptionSelect('upload')} className="option-btn">Upload Products</button>
            </>
          )}

          {selectedOption === 'design' && <DesignBike onBack={handleBack} />}
          {selectedOption === 'upload' && <UploadProducts onBack={handleBack} />}
        </div>
      </div>
    </div>
  );
}

export default App;