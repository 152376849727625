import React, { useState } from 'react';

function DesignBike({ onBack }) {
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email submitted:', email, comment);
    alert('Thanks for your interest! We will notify you once the 3D bike configurator is ready.');
  };

  return (
    <div className="design-bike">
      <h2>Ops! We are working our ass off to optimize this functionality.</h2>
      <p>Enter your email and we will notify you as soon as we are ready!</p>

      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          placeholder="Enter your email" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea 
          placeholder="Optional comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>

      {/* Back button to go back to the main menu */}
      <button onClick={onBack} className="back-btn">Back</button>
    </div>
  );
}

export default DesignBike;